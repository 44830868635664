import RadioButton from 'components/ui/FormElements/RadioButton'
import { useEffect, useState } from 'react'

import { ShiurimFilters } from '../index'
import { RadioGroup } from './styled'

type Props = { filters: ShiurimFilters; onChangeFilters: (filters: ShiurimFilters) => void }

export enum PLAYED_OPTIONS {
  ALL = 'All',
  YES = 'Yes',
  NO = 'No',
}

function PlayedFilter({ filters, onChangeFilters }: Props) {
  const [selectedOption, setSelectedOption] = useState<PLAYED_OPTIONS>(filters.played?.option || PLAYED_OPTIONS.ALL)

  useEffect(() => {
    setSelectedOption(filters.played?.option || PLAYED_OPTIONS.ALL)
  }, [filters])

  useEffect(() => {
    if (selectedOption === PLAYED_OPTIONS.ALL) {
      const newFilters = { ...filters }
      delete newFilters.played

      onChangeFilters(newFilters)
    }

    if (selectedOption === PLAYED_OPTIONS.YES) {
      onChangeFilters({
        ...filters,
        played: {
          option: selectedOption,
          value: true,
        },
      })
    }

    if (selectedOption === PLAYED_OPTIONS.NO) {
      onChangeFilters({
        ...filters,
        played: {
          option: selectedOption,
          value: false,
        },
      })
    }
  }, [selectedOption, onChangeFilters])

  return (
    <>
      <RadioGroup value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}>
        <RadioButton value={PLAYED_OPTIONS.ALL}>All</RadioButton>
        <RadioButton value={PLAYED_OPTIONS.YES}>Yes</RadioButton>
        <RadioButton value={PLAYED_OPTIONS.NO}>No</RadioButton>
      </RadioGroup>
    </>
  )
}

export default PlayedFilter
