import { Button } from 'antd'
import { ReactComponent as DownArrow } from 'assets/icons/down-arrow.svg'
import Search from 'components/ui/FormElements/Search'
import { COLORS } from 'constants/colors'
import { device } from 'constants/responsive'
import styled from 'styled-components'

export const Count = styled.p`
  min-width: 120px;

  color: ${COLORS.text.default};
  font-size: 24px;
  line-height: 1.3;
  font-weight: 500;

  white-space: nowrap;
`

export const FiltersRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 24px;

  @media ${device.laptop} {
    flex-direction: row-reverse;
  }
`

export const SearchStyled = styled(Search)`
  width: 100%;
  max-width: 100%;

  margin: 0 20px;
`

export const SortButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 42px;
  margin-left: 12px;

  & > svg {
    flex-shrink: 0;
  }

  @media ${device.mobileS} {
    display: none;
  }
`

export const SortButtonText = styled.p`
  width: 55px;
`

export const SortIcon = styled(DownArrow)<{ isASC: boolean }>`
  ${({ isASC }) => isASC && 'transform: rotate(180deg)'}
`
