import { PropsWithChildren, useEffect, useMemo, useState } from 'react'

import PeriodFilter, { PERIOD_OPTIONS } from '../PeriodFilter'
import PlayedFilter from '../PlayedFilter'
import SeriesFilter from '../SeriesFilter'
import SubtopicFilter from '../SubtopicFilter'
import TopicFilter from '../TopicFilter'
import { ShiurimFilters } from '../index'
import { DownArrow, ResetButton, SubmitButton } from '../styled'
import { ButtonsWrapper, ContentWrapper, QuickFilterButton, QuickFilterPopover } from './styled'

export enum QUICK_FILTER_TYPE {
  PLAYED = 'Played',
  PERIOD = 'Period',
  SERIES = 'Series',
  TOPIC = 'Topic',
  SUB_TOPIC = 'Sub-topic',
}

const typeToFilterName: { [key in QUICK_FILTER_TYPE]: keyof ShiurimFilters } = {
  [QUICK_FILTER_TYPE.PLAYED]: 'played',
  [QUICK_FILTER_TYPE.PERIOD]: 'period',
  [QUICK_FILTER_TYPE.SERIES]: 'series',
  [QUICK_FILTER_TYPE.TOPIC]: 'topics',
  [QUICK_FILTER_TYPE.SUB_TOPIC]: 'subtopics',
}

type Props = PropsWithChildren<{
  type: QUICK_FILTER_TYPE
  filters: ShiurimFilters
  newFilters: ShiurimFilters
  noTopics?: boolean
  onResetOne: (filterName: keyof ShiurimFilters) => void
  onApply: () => void
  onChangeFilters: (filters: ShiurimFilters) => void
}>

function QuickFilter({ type, filters, newFilters, noTopics, onResetOne, onApply, onChangeFilters }: Props) {
  const [isOpen, setIsOpen] = useState(false)
  console.log('filters', filters)
  console.log('newFilters', newFilters)

  const typeToComponent = {
    [QUICK_FILTER_TYPE.PLAYED]: <PlayedFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.PERIOD]: <PeriodFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.SERIES]: <SeriesFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.TOPIC]: <TopicFilter filters={newFilters} onChangeFilters={onChangeFilters} />,
    [QUICK_FILTER_TYPE.SUB_TOPIC]: (
      <SubtopicFilter filters={newFilters} noTopics={noTopics} onChangeFilters={onChangeFilters} />
    ),
  }

  useEffect(() => {
    onChangeFilters(filters)
  }, [filters, isOpen, onChangeFilters])

  const onReset = () => {
    onResetOne(typeToFilterName[type])
    onToggleVisibility()
  }
  const onApplyClick = () => {
    onApply()
    onToggleVisibility()
  }

  const onToggleVisibility = () => setIsOpen(!isOpen)

  const currentNewFilter = newFilters[typeToFilterName[type]]

  const isButtonsShown = useMemo(() => {
    if (!currentNewFilter) return false

    switch (type) {
      case QUICK_FILTER_TYPE.PLAYED:
        return (currentNewFilter as ShiurimFilters['played'])?.value !== undefined
      case QUICK_FILTER_TYPE.PERIOD:
        return (
          (currentNewFilter as ShiurimFilters['period'])?.option !== PERIOD_OPTIONS.ALL_TIME &&
          !!(currentNewFilter as ShiurimFilters['period'])?.endDate
        )
      default:
        return !!(currentNewFilter as ShiurimFilters['series'] | ShiurimFilters['subtopics'] | ShiurimFilters['topics'])
          ?.length
    }
  }, [currentNewFilter, type])

  const currentFilter = filters[typeToFilterName[type]]

  const isActive = useMemo(() => {
    switch (type) {
      case QUICK_FILTER_TYPE.PLAYED:
        return (currentFilter as ShiurimFilters['played'])?.value !== undefined
      case QUICK_FILTER_TYPE.PERIOD:
        return !!(currentFilter as ShiurimFilters['period'])?.startDate
      default:
        return !!(currentFilter as ShiurimFilters['series'] | ShiurimFilters['subtopics'] | ShiurimFilters['topics'])
          ?.length
    }
  }, [currentFilter, type])

  const title = useMemo(() => {
    switch (type) {
      case QUICK_FILTER_TYPE.PLAYED:
      case QUICK_FILTER_TYPE.PERIOD:
        return (currentFilter as ShiurimFilters['played'] | ShiurimFilters['period'])?.option
      default:
        return (currentFilter as ShiurimFilters['series'] | ShiurimFilters['subtopics'] | ShiurimFilters['topics'])?.[0]
          ?.title
    }
  }, [currentFilter, type])

  const count = useMemo(() => {
    if (
      type === QUICK_FILTER_TYPE.PLAYED ||
      type === QUICK_FILTER_TYPE.PERIOD ||
      (currentFilter as ShiurimFilters['series'] | ShiurimFilters['subtopics'] | ShiurimFilters['topics'])?.length -
        1 ===
        0
    ) {
      return ''
    }
    return `+${
      (currentFilter as ShiurimFilters['series'] | ShiurimFilters['subtopics'] | ShiurimFilters['topics'])?.length - 1
    }`
  }, [currentFilter, type])

  return (
    <QuickFilterPopover
      placement="bottom"
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}
      destroyTooltipOnHide
      content={
        <ContentWrapper isButtonsShown={isButtonsShown}>
          {typeToComponent[type]}
          {isButtonsShown && (
            <ButtonsWrapper>
              <SubmitButton type="primary" onClick={onApplyClick}>
                APPLY
              </SubmitButton>
              <ResetButton onClick={onReset}>RESET</ResetButton>
            </ButtonsWrapper>
          )}
        </ContentWrapper>
      }
    >
      <QuickFilterButton isActive={isActive} onClick={onToggleVisibility}>
        {`${type}${isActive ? `: ${title} ${count}` : ''}`}
        <DownArrow isActive={isOpen} />
      </QuickFilterButton>
    </QuickFilterPopover>
  )
}

export default QuickFilter
